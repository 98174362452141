import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import tippy from 'tippy.js'
import * as htmx from 'htmx.org'

window.htmx = htmx
window.Alpine = Alpine

async function shareImage(url, name) {
	const response = await fetch(url)
	const blob = await response.blob()
	const file = new File([blob], name ?? `image.jpg`, { type: blob.type }) // TODO: get extension from blob.type?

	navigator.share({ files: [file] })
}

Alpine.plugin(focus)

window.Alpine.directive('tippy', (el, { expression }) => {
	tippy(el, { content: expression })
})

Alpine.bind('ImageShareButton', () => ({
	'@click'() {
		const url = new URL(this.imageUrl)
		const imageName = url.pathname.split("/").slice(-1)[0]
		shareImage(this.imageUrl, imageName)
	},
}))

const precisionToRadius = {
	"RegionLevel": 115_000,
	"CityLevel": 15_000,
}

const precisionToZoom = {
	"RegionLevel": 7,
	"CityLevel": 10,
	"FullLocation": 12,
}

Alpine.bind('ImageMap', () => ({
	/* 'x-data'() {
		return {
			async init() {
				console.log(this)
				await this.$nextTick()
				this.map = L.map(this.$el).setView([this.latitude, this.longitude], precisionToZoom[this.precision])
	
				L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 19,
					attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
				}).addTo(this.map)

				if (this.precision === "FullLocation") {
					L.marker([this.latitude, this.longitude]).addTo(this.map)
				} else {
					L.circle([this.latitude, this.longitude], {radius: precisionToRadius[this.precision]}).addTo(this.map)
				}
			},
			destroy() {
				this.map.remove()
			}
		}
	} */
	async 'x-effect'() {
		if (this.modalOpen && !this.map) {
			await this.$nextTick()
			this.map = L.map(this.$el).setView([this.latitude, this.longitude], precisionToZoom[this.precision])

			L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 19,
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
			}).addTo(this.map)

			if (this.precision === "FullLocation") {
				L.marker([this.latitude, this.longitude]).addTo(this.map)
			} else {
				L.circle([this.latitude, this.longitude], {radius: precisionToRadius[this.precision]}).addTo(this.map)
			}

			if (this.weather) {
				var imageUrl = this.weather.url,
					imageBounds = [[this.weather.north, this.weather.west], [this.weather.south, this.weather.east]]

				if (navigator.connection && navigator.connection.saveData) {
					imageUrl += '?width=512'
				}
				
				L.imageOverlay(imageUrl, imageBounds, {
					attribution: '&copy; <a href="https://www.xweather.com/">Vaisala Xweather</a>',
				}).addTo(this.map)
			}
		} else if (!this.modalOpen && this.map) {
			const map = this.map
			setTimeout(() => map.remove(), 200)
			this.map = undefined
		}
	}
}))

Alpine.start()

new Array(document.getElementsByTagName('time')).forEach(time => {
	const date = new Date(time.dateTime)
	const str = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
    timeStyle: 'medium'
  }).format(date)
	time.innerHTML = str
}) // nea moment
